import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { sortBy } from 'lodash';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import CashOut from 'components/CashOut';
import { FAVORITES_TYPES, SportIds } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLinks,
  AsianViewSections
} from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { MOBILE_FAVOURITES_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent } from 'redux/modules/asianView/type';
import { getClosedMarketsIds } from 'redux/modules/marketsPrices/selectors';

import CollapseTime from '../CollapseTime';

import ColumnName from './ColumnName';
import MarketRules from './MarketRules';

import styles from './styles.module.scss';

interface CompetitionTitleProps {
  competitionId: string;
  events?: TAsianViewEvent[];
  name: string;
  isOpen: boolean;
  isSingleLine?: boolean;
  onCollapse: (isOpen: boolean) => void;
  date?: number;
  isHideFavorite?: boolean;
  isTab?: boolean;
  isCollapseEnabled?: boolean;
  cashOutEnabled?: boolean;
  showCashOut?: boolean;
  competitionTotalMatched?: number;
}

const CompetitionTitle = ({
  competitionId,
  name,
  events = [],
  isOpen,
  isSingleLine = false,
  onCollapse,
  date,
  isHideFavorite = false,
  isTab = false,
  isCollapseEnabled = true,
  cashOutEnabled,
  showCashOut = false,
  competitionTotalMatched
}: CompetitionTitleProps) => {
  const { t } = useTranslation();
  const { marketLink, sportId = SportIds.SOCCER } = useParams();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const isMobileFavourites = pathname.includes(MOBILE_FAVOURITES_BASE_URL);
  const isMatchOddsHalfTime = marketLink === AsianViewMarketLinks.MATCH_ODDS_HALF_TIME;
  const isHdpAndOu = marketLink === AsianViewMarketLinks.HDP_AND_OU;
  const isOddEven = marketLink === AsianViewMarketLinks.ODD_EVEN;
  const isOutRight = marketLink === AsianViewMarketLinks.OUTRIGHT;
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId) || isMobileFavourites;
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleMarketView = !!outrightCompetitionId;
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const isSearchQuery = query.length >= SEARCH_QUERY_LENGTH;
  const marketRulesModalClassNames = {
    header: classNames({ [styles.modal__header]: isMobileAsianView }),
    body: classNames({ [styles.modal__body]: isMobileAsianView }),
    title: classNames({ [styles.modal__title]: isMobileAsianView }),
    content: classNames({
      [styles.modal__content__landscape]: isLandscapeView,
      [styles.modal__content]: isMobileAsianView
    })
  };

  const getRulesMarketIdByType = (type: string) => {
    const currentMarket = events
      .map(event =>
        sortBy(event.sections[AsianViewSections.TOP] || [], ['marketType'])
          .filter(market => !closedMarketIds.includes(market.id))
          .find(market => market.marketType.includes(type))
      )
      .filter(Boolean)[0];
    return currentMarket && currentMarket.runners.length > 0 ? currentMarket : null;
  };

  const marketOdds = getRulesMarketIdByType(MARKET_TYPES.matchOdds);
  const halfTime = getRulesMarketIdByType(MARKET_TYPES.halfTime);
  const goalLines = getRulesMarketIdByType(MARKET_TYPES.altTotalGoals);
  const asianHandicap = getRulesMarketIdByType(MARKET_TYPES.asianHandicap);
  const firstHalfGoalsMarkets = getRulesMarketIdByType(MARKET_TYPES.firstHalfGoals);

  const columns = isSingleLine
    ? [
        {
          name: t('asianView.labels.column.HDP'),
          market: asianHandicap,
          class: styles.competition__goal,
          brandingClass: `${branding.SINGLE_LINE_HDP_GOAL_HEADER} ${branding.LEAGUE_HEADER_BORDER}`
        },
        {
          name: t('asianView.labels.column.home'),
          market: null,
          class: styles.competition__home,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.column.away'),
          market: null,
          class: styles.competition__away,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.column.goal'),
          market: goalLines,
          class: styles.competition__goal,
          brandingClass: `${branding.SINGLE_LINE_HDP_GOAL_HEADER} ${branding.LEAGUE_HEADER_BORDER}`
        },
        {
          name: t('asianView.labels.column.over'),
          market: null,
          class: styles.competition__over,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.column.under'),
          market: null,
          class: styles.competition__under,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.column.goal'),
          market: firstHalfGoalsMarkets,
          class: styles.competition__goal,
          brandingClass: `${branding.SINGLE_LINE_HDP_GOAL_HEADER} ${branding.LEAGUE_HEADER_BORDER}`
        },
        {
          name: t('asianView.labels.column.over'),
          market: null,
          class: styles.competition__over,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.column.under'),
          market: null,
          class: styles.competition__under,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        }
      ]
    : [
        {
          name: t('asianView.labels.market.FT_HDP'),
          market: asianHandicap,
          class: styles.competition__market__big,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.market.FT_OU'),
          market: goalLines,
          class: styles.competition__market__big,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.market.FT_1X2'),
          market: marketOdds,
          class: styles.competition__market,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.market.1H_OU'),
          market: firstHalfGoalsMarkets,
          class: styles.competition__market__big,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        },
        {
          name: t('asianView.labels.market.1H_1X2'),
          market: halfTime,
          class: styles.competition__market,
          brandingClass: branding.LEAGUE_HEADER_BORDER
        }
      ];

  return (
    <div
      onClick={() => isTab && onCollapse(!isOpen)}
      className={classNames(
        'biab_asian-view-competition-title',
        styles.competition__title,
        branding.LEAGUE_HEADER,
        branding.LEAGUE_HEADER_BORDER,
        branding.MARKET_BORDER,
        {
          [styles.competition__withOutBorder]: (isTab || isOutRight || isSingleMarketView) && !isOpen,
          [styles.competition__tab]: isTab,
          [styles.competition__tab__open]: isTab && isOpen,
          [styles.competition__mobileTab]: isTab && isMobileAsianView
        }
      )}
      {...(competitionTotalMatched !== undefined ? { 'data-competition-total-matched': competitionTotalMatched } : {})}
    >
      <div
        className={classNames('biab_asian-view-competition-actions', styles.competition__actions, {
          [styles.competition__actionsTab]: isHideFavorite || !isCollapseEnabled
        })}
      >
        {isCollapseEnabled && !isMobileAsianView && (
          <i
            onClick={() => !isTab && onCollapse(!isOpen)}
            className={classNames('fa2 biab_expand', styles.competition__expand, {
              'fa2-arrow-up': isOpen,
              'fa2-arrow-down': !isOpen
            })}
          />
        )}
        {isCollapseEnabled && isMobileAsianView && (
          <i
            onClick={() => !isTab && onCollapse(!isOpen)}
            className={classNames('av-icon biab_expand', styles.competition__expand, {
              'av-icon-arrow-up': isOpen,
              'av-icon-arrow-down': !isOpen
            })}
          />
        )}
        {!isHideFavorite && (
          <AsianViewFavouriteStar
            favourite={{
              entryName: name,
              entryId: competitionId,
              entryType: FAVORITES_TYPES.competition,
              sportId
            }}
          />
        )}
      </div>
      <div className={classNames('biab_asian-view-competition-name-wrapper', styles.competition__name__wrapper)}>
        <span
          className={classNames('biab_asian-view-competition-name', styles.competition__name, {
            [styles.competition__tabName]: isTab || isOutRight || isSingleMarketView,
            [styles.competition__nameOdds]: isOddEven
          })}
        >
          <span data-tooltip-id={'tooltip'} data-tooltip-html={name}>
            {name}
          </span>
          {(isOutRight || isSingleMarketView) && showCashOut && (
            <CashOut
              showLabel={false}
              showInfoIcon={false}
              className={{ icon: styles.competition__cashOutIcon }}
              disabled={!cashOutEnabled}
              isAvPLTableAvailable
            />
          )}
        </span>
      </div>
      {(isHdpAndOu || isFavouriteView || isPopularLinkView || isSearchQuery || isSingleView) &&
        !isTab &&
        !isSingleMarketView && (
          <div className={styles.competition__colWrapper}>
            {columns.map((column, index) => (
              <div
                key={index}
                className={classNames(styles.competition__col, column.class, column.brandingClass ?? '')}
              >
                <ColumnName
                  name={column.name}
                  marketId={column.market?.id || null}
                  modalClasses={{
                    dialog: styles.modal__dialog,
                    ...marketRulesModalClassNames
                  }}
                />
              </div>
            ))}
            <div
              className={classNames(
                styles.competition__col,
                styles.competition__streaming,
                branding.LEAGUE_HEADER_BORDER
              )}
            />
          </div>
        )}
      {(isHdpAndOu || isFavouriteView || isPopularLinkView || isSearchQuery || isSingleView) &&
        isTab &&
        !isSingleMarketView && (
          <div className={styles.competition__rules}>
            <MarketRules marketId={competitionId} modalClasses={marketRulesModalClassNames} />
          </div>
        )}
      {isMatchOddsHalfTime &&
        !isFavouriteView &&
        !isPopularLinkView &&
        !isSearchQuery &&
        !isSingleMarketView &&
        !isSingleView && (
          <div className={styles.competition__matchOddsHT__cols}>
            <div className={styles.competition__matchOddsHT__cols__container}>
              {['1', 'X', '2', '1', 'X', '2'].map((label, index) => (
                <div
                  className={classNames(styles.competition__matchOddsHT__col, branding.LEAGUE_HEADER_BORDER)}
                  key={index}
                >
                  {label}
                </div>
              ))}
            </div>
            <div
              className={classNames(
                styles.competition__col,
                styles.competition__streaming,
                branding.LEAGUE_HEADER_BORDER
              )}
            />
          </div>
        )}
      {isOddEven &&
        !isFavouriteView &&
        !isPopularLinkView &&
        !isSearchQuery &&
        !isSingleMarketView &&
        !isSingleView && (
          <div className={styles.competition__matchOddsHT__cols}>
            <div
              className={classNames(styles.competition__col, styles.competition__odds, branding.LEAGUE_HEADER_BORDER)}
            >
              <ColumnName name={t('asianView.labels.column.odd')} />
            </div>
            <div
              className={classNames(styles.competition__col, styles.competition__even, branding.LEAGUE_HEADER_BORDER)}
            >
              <ColumnName name={t('asianView.labels.column.even')} />
            </div>
            <div
              className={classNames(
                styles.competition__col,
                styles.competition__streaming,
                branding.LEAGUE_HEADER_BORDER
              )}
            />
          </div>
        )}
      {(isOutRight || isSingleMarketView) &&
        !isFavouriteView &&
        !isPopularLinkView &&
        !isSearchQuery &&
        !isSingleView && (
          <div className={styles.competition__outRight}>
            {date && <CollapseTime date={date} />}
            <div className={styles.rulesIcon}>
              <MarketRules marketId={competitionId} modalClasses={marketRulesModalClassNames} />
            </div>
          </div>
        )}
    </div>
  );
};

export default CompetitionTitle;
